<template>
  <div class="main-content">
    <loading v-if="Loading"></loading>

    <b-row v-if="!Loading">

      <b-col class="col-lg-3 col-12">
        <b-card :class="{ 'card-item': true, 'card-active': selfDuty === '4' }" @click="handleCardClick('4')">On Hold ({{totalHold}})</b-card>
      </b-col>
      <b-col class="col-lg-3 col-12">
        <b-card :class="{ 'card-item': true, 'card-active': selfDuty === '1' }" @click="handleCardClick('1')">Kota ({{ totalKota }})</b-card>
      </b-col>
      <b-col class="col-lg-3 col-12">
        <b-card :class="{ 'card-item': true, 'card-active': selfDuty === '2' }" @click="handleCardClick('2')">Sandy Shores ({{ totalSS }})</b-card>
      </b-col>
      <b-col class="col-lg-3 col-12">
        <b-card :class="{ 'card-item': true, 'card-active': selfDuty === '3' }" @click="handleCardClick('3')">Paleto & Roxwood ({{ totalPaleto }})</b-card>
      </b-col>

      <b-col class="col-lg-3 col-12">
        <b-table small :items="tableHold" :fields="fields2" show-empty>
          <template v-slot:cell(fullname)="row">
            <div class="display-row" @click="goToDetail(row.item)">
              <img :src="getBadgeSrc(row.item.badge)" height="25px" style="margin-right: 5px;" :class="{ 'glowing-image': row.item.grade > 20 }"/>
              <img :src="getColorUnit(row.item.police_type)" height="25px" width="3px" style="margin-right: 5px;" /> 
              {{ uppercaseFirstLetter(row.item.fullname) }}
            </div>
          </template>
          <template #empty>
            <div class="text-center">
              Tidak ada 
            </div>
          </template>
        </b-table>
      </b-col>
      <b-col class="col-lg-3 col-12">
        <b-table small :items="tableKota" :fields="fields2" show-empty>
          <template v-slot:cell(fullname)="row">
            <div class="display-row" @click="goToDetail(row.item)">
              <img :src="getBadgeSrc(row.item.badge)" height="25px" style="margin-right: 5px;" :class="{ 'glowing-image': row.item.grade > 20 }"/>
              <img :src="getColorUnit(row.item.police_type)" height="25px" width="3px" style="margin-right: 5px;" /> 
              {{ uppercaseFirstLetter(row.item.fullname) }}
            </div>
          </template> 
          <template #empty>
            <div class="text-center">
              Tidak ada 
            </div>
          </template>
        </b-table>
      </b-col>
      <b-col class="col-lg-3 col-12">
        <b-table small :items="tableSS" :fields="fields2" show-empty>
          <template v-slot:cell(fullname)="row">
            <div class="display-row" @click="goToDetail(row.item)">
              <img :src="getBadgeSrc(row.item.badge)" height="25px" style="margin-right: 5px;" :class="{ 'glowing-image': row.item.grade > 20 }" />
              <img :src="getColorUnit(row.item.police_type)" height="25px" width="3px" style="margin-right: 5px;" /> 
              {{ uppercaseFirstLetter(row.item.fullname) }}
            </div>
          </template>
          <template #empty>
            <div class="text-center">
              Tidak ada 
            </div>
          </template>
        </b-table>
      </b-col>
      <b-col class="col-lg-3 col-12">
        <b-table small :items="tablePaleto" :fields="fields2" show-empty>
          <template v-slot:cell(fullname)="row">
            <div class="display-row" @click="goToDetail(row.item)">
              <img :src="getBadgeSrc(row.item.badge)" height="25px" style="margin-right: 5px;" :class="{ 'glowing-image': row.item.grade > 20 }" />
              <img :src="getColorUnit(row.item.police_type)" height="25px" width="3px" style="margin-right: 5px;" /> 
              {{ uppercaseFirstLetter(row.item.fullname) }}
            </div>
          </template>
          <template #empty>
            <div class="text-center">
              Tidak ada 
            </div>
          </template>
        </b-table>
      </b-col>
      
    </b-row>
    <div v-if="LoadingDetail" class="loading-overlay">
      <div class="loading-spinner"></div>
    </div>
  </div>
</template>

<script>
import axios from "axios";
import Loading from '@/templates/Loading.vue'
export default {
  name: 'Dashboard',
  components: {
    Loading
  },
  data() {
    return {
      Loading : false,
      LoadingDetail : false,
      selfDuty : 0,
      tableData: [],
      
      tableKota: [],
      totalKota: 0,
      
      tableSS: [],
      totalSS: 0,

      tablePaleto: [],
      totalPaleto: 0,

      tableHold: [],
      totalHold: 0,
      
      fields: [
        {key:'fullname',label:'Nama Lengkap'},
        {key:'online',label:'Total'},
      ],
      fields2: [
        {key:'fullname',label:'LIST ANGGOTA'},
      ]
    };
  },
  methods: {
    init(){
      this.getData();
    },
    getData() {
      this.Loading = true;
      axios.post("idp/police-on-duty/list",{id:null}).then((response) => {
        this.tableData = response.data.data
        this.tableKota = response.data.kota
        this.tableSS = response.data.ss
        this.tablePaleto = response.data.paleto
        this.tableHold = response.data.hold
        this.selfDuty = response.data.self_duty

        this.totalHold = this.tableHold.length;
        this.totalKota = this.tableKota.length;
        this.totalSS = this.tableSS.length;
        this.totalPaleto = this.tablePaleto.length;

      }).catch((error) => {
        if (error.status === 4000) {
          localStorage.clear();
          this.$router.push({ path: "/login" });
          ({
            title: "Login Expired",
            message: "Sesi Login Habis",
          });
        }
      }).finally(() => {
        this.Loading = false;
        this.modalShow = false;
      });
    },
    uppercaseFirstLetter(str) {
      // Ubah seluruh string menjadi lowercase
      str = str.toLowerCase();
      // Ambil huruf pertama dan ubah ke uppercase
      return str.charAt(0).toUpperCase() + str.slice(1);
    },
    handleCardClick(moveto){
      this.Loading = true;

      axios.post("idp/police-on-duty/move",{moveto:moveto}).then((response) => {

      }).catch((error) => {
        if (error.status === 4000) {
          localStorage.clear();
          this.$router.push({ path: "/login" });
          ({
            title: "Login Expired",
            message: "Sesi Login Habis",
          });
        }
      }).finally(() => {
        this.getData();
        // this.Loading = false;
      });
    },
    getBadgeSrc(path) {
      if(path == 'serpa.jpg'){
        return require('@/assets/images/avatars/serpa.jpg');
      }else if(path == 'serdu.jpg'){
        return require('@/assets/images/avatars/serdu.jpg');
      }else if(path == 'serpol.jpg'){
        return require('@/assets/images/avatars/serpol.jpg');
      }else if(path == 'serka.jpg'){
        return require('@/assets/images/avatars/serka.jpg');
      }else if(path == 'pelma.jpg'){
        return require('@/assets/images/avatars/pelma.jpg');
      }else if(path == 'peldu.jpg'){
        return require('@/assets/images/avatars/peldu.jpg');
      }else if(path == 'letma.jpg'){
        return require('@/assets/images/avatars/letma.jpg');
      }else if(path == 'letdu.jpg'){
        return require('@/assets/images/avatars/letdu.jpg');
      }else if(path == 'kapten.jpg'){
        return require('@/assets/images/avatars/kapten.jpg');
      }else if(path == 'mayor.jpg'){
        return require('@/assets/images/avatars/mayor.jpg');
      }else if(path == 'letkol.jpg'){
        return require('@/assets/images/avatars/letkol.jpg');
      }else if(path == 'kolonel.jpg'){
        return require('@/assets/images/avatars/kolonel.jpg');
      }else if(path == 'serjen.jpg'){
        return require('@/assets/images/avatars/serjen.jpg');
      }else if(path == 'mayjen.jpg'){
        return require('@/assets/images/avatars/mayjen.jpg');
      }else if(path == 'letjen.jpg'){
        return require('@/assets/images/avatars/letjen.jpg');
      }else if(path == 'jendral.jpg'){
        return require('@/assets/images/avatars/jendral.jpg');
      }else if(path == 'kadop.jpg'){
        return require('@/assets/images/avatars/kadop.jpg');
      }else if(path == 'sekertaris.jpg'){
        return require('@/assets/images/avatars/sekertaris.jpg');
      }
    },
    getColorUnit(police_type) {
      if(police_type == '1'){
        return require('@/assets/images/avatars/polsip.jpg');
      }else if(police_type == '3'){
        return require('@/assets/images/avatars/polmas.jpg');
      }else if(police_type == '2'){
        return require('@/assets/images/avatars/polsus.jpg');
      }else{
        return require('@/assets/images/avatars/golden.jpg');
      }
    },
    goToDetail(data){
      this.LoadingDetail = true;
      axios.post("idp/user/get-id",{identifier:data.identifier}).then((response) => {
        if(response.data.status == 2000){
          this.$router.push({ path: "/idp-warga-detail/"+response.data.data.id });
        }
      }).catch((error) => {
        if (error.status === 4000) {
          localStorage.clear();
          this.$router.push({ path: "/login" });
          ({
            title: "Login Expired",
            message: "Sesi Login Habis",
          });
        }
      }).finally(() => {
        this.LoadingDetail = false;
        this.modalShow = false;
      });
    },
  },
  mounted(){
    this.init();
  }
}
</script>
<style scoped>

.card-item {
  text-align: center;
  border-radius: 5px;
  box-shadow: 0px 3px 10px rgba(0, 0, 0, 0.1);
  transition: transform 0.3s ease, box-shadow 0.3s ease;
}

.card-item:hover {
  color: black;
  background-color: yellowgreen; /* Ubah warna background saat dihover */
  transform: translateY(-5px); /* Efek translasi ke atas saat dihover */
  box-shadow: 0px 3px 10px rgba(0, 0, 0, 0.2); /* Efek bayangan saat dihover */
  cursor: pointer; /* Ubah kursor menjadi pointer saat dihover */
}

.card-active {
  color: black;
  font-weight: bold;
  background-color: yellowgreen; /* Ubah warna background saat dihover */
}

.display-row {
  display: flex;
  align-items: center;
  transition: transform 0.1s;
}

.display-row:hover {
  transform: scale(1.2) translateX(8%);
  cursor: pointer;
  animation: bounce 0.3s;
  /* box-shadow: 0 0 15px rgba(0, 255, 0, 0.6); */
  color:yellow;
}

.loading-overlay {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.5);
  display: flex;
  align-items: center;
  justify-content: center;
  z-index: 9999; /* pastikan overlay di atas konten lain */
}

.loading-spinner {
  border: 8px solid rgba(0, 0, 0, 0.1);
  border-radius: 50%;
  border-top: 8px solid #fff;
  width: 50px;
  height: 50px;
  animation: spin 1s linear infinite;
}

@keyframes spin {
  0% { transform: rotate(0deg); }
  100% { transform: rotate(360deg); }
}

@keyframes glowZoom {
    0% {
        transform: scale(1);
        filter: brightness(1);
    }
    50% {
        transform: scale(1.05); /* Zoom sedikit */
        filter: brightness(1.9); /* Efek pendar */
    }
    100% {
        transform: scale(1);
        filter: brightness(1);
    }
}

/* Kelas untuk gambar dengan efek glowing dan zoom */
.glowing-image {
    animation: glowZoom 1.3s infinite; /* Durasi dan infinite loop */
}
</style>