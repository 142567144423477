import { render, staticRenderFns } from "./PoliceOnDuty.vue?vue&type=template&id=3bd4efaa&scoped=true&"
import script from "./PoliceOnDuty.vue?vue&type=script&lang=js&"
export * from "./PoliceOnDuty.vue?vue&type=script&lang=js&"
import style0 from "./PoliceOnDuty.vue?vue&type=style&index=0&id=3bd4efaa&prod&scoped=true&lang=css&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "3bd4efaa",
  null
  
)

export default component.exports